.orderProfileWrapper {
  display: flex;
  padding: 20px 28px;
  background: #f8f8f8 !important;
  border-radius: 5px;
  align-items: center;
}

.mypro-main-outlet-wrapper {
  margin-top: 43px;
}

.profile-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ProfileLine {
  border-top: 1px solid #b8b8b8;
  opacity: 1;
  margin: 9px 0 16px 0;
}

@media screen and (max-width: 480px) {
  .mypro-main-outlet-wrapper {
    margin-top: 10px;
  }
}

.mypro-wrap .ordersProfileWrapper {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.mypro-wrap {
  height: fit-content;
  border: 0;
}

.orders-outer {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 1em;
}

.mypro-wrap .profile-delete {
  margin-top: 20px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.profile-delete {
  position: relative;
}

.profile-delete h3 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--font-family);
  line-height: 11px;
  margin-bottom: 0px;
}

.profile-delete .accordion__button {
  padding: 20px;
}

.profile-delete .accordion__button:after {
  display: inline-block;
  content: url('assets/icons/caret-down-arrow.svg');
  position: absolute;
  right: 2px;
}

.profile-delete .accordion__button::after {
  margin-right: 14px;
  top: 22%;
  transform-origin: center;
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.profile-delete .accordion__button[aria-expanded='true'] {
  padding-bottom: 0;
}

.profile-delete .accordion__button[aria-expanded='true']::after,
.profile-delete .accordion__button[aria-selected='true']::after {
  transform: rotate(180deg);
  float: right;
  top: 10px;
}

.profile-delete .accordion__panel {
  padding: 20px;
  padding-top: 0;
}

.delete-acc {
  margin-top: 28px;
  cursor: pointer;
  color: var(--wine);
  font-size: 12px;
  line-height: 18px;
}

.verify-email-header .btn-close {
  background: url('../../assets/icons/modalCross.svg');
  background-repeat: no-repeat;
  border-radius: unset;
  opacity: unset;
  box-shadow: unset;
  position: relative;
  top: 7px;
}

.add-new-mobile-number-header .btn-close {
  background: url('../../assets/icons/modalCross.svg');
  background-repeat: no-repeat;
  border-radius: unset;
  opacity: unset;
  box-shadow: unset;
  position: relative;
  top: 7px;
}

.verify-email-header .btn-close:hover {
  opacity: 1;
}

.add-new-mobile-number-header .btn-close {
  opacity: 1;
}

.add-new-mobile-number-header .btn-close:hover {
  opacity: 1;
}

.profileName {
  text-transform: capitalize;
  font-size: 11px;
}

.orderedFreeProduct {
  margin-top: 8px;
}

.shpCartDetailTxt {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (min-width: 427px) and (max-width: 480px) {
  .medProdNAme {
    font-size: 12px !important;
  }
}

.delAdd {
  cursor: pointer;
}

.twoFA .formInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.twoFA .loginCta {
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 30px;
  width: 100%;
  max-width: 260px;
  height: 30px;
}

.twoFA .loginCta:disabled {
  border-color: #d9d9d9;
  background: #d9d9d9;
  color: #787878;
}

.number-input {
  padding: 15px !important;
  text-transform: none !important;
}

.edit-checkbox-item {
  display: flex;
}

.otpInput {
  font-size: 15px;
  text-align: center;
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5);
  border: none !important;
  font-weight: 500;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  -moz-appearance: textfield;
}

.otpInput::-webkit-inner-spin-button,
.otpInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.otpInput:focus-visible {
  outline-color: var(--wine);
}

.quid-clearDues-frame {
  width: 100%;
  min-height: 800px;
}

.otpInput:not([value='']),
.otpInput:not(:empty) {
  background: var(--wine);
  color: var(--white);
}

.otpInput:not([value=''])::selection {
  background-color: var(--wine);
}

.otp-input {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.otp_verify form {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
}

.otp_verify .otpWrapper {
  text-align: center;
}

.mobileChangePopContent p.text-danger.code-err {
  margin: 0 6px;
}

.profile-contact .modal-dialog {
  font-family: var(--font-family);
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
}

.profile-contact .modal-content {
  padding: 20px;
  top: 100px;
}

.profile-contact .modal-header {
  padding: unset;
  padding-bottom: 10px;
  border-bottom: 2px solid #787878;
}

.profile-contact .modal-header button {
  display: flex;
  background: none;
  border: none;
}

.profile-contact .modal-title {
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
}

.profile-contact .modal-body {
  padding: 0;
  padding-top: 16px;
}

.profile-contact .popMainContent {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.profile-contact .popMainContent p {
  margin-bottom: 0px;
}

.profile-contact .popMainContent .editIcon {
  border: none;
  cursor: pointer;
  background: none;
}

.profile-contact label {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  width: 100%;
  margin-bottom: 12px;
}

.profile-contact .mobileInputWrapper {
  position: relative;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
}

.profile-contact .mobileInputWrapper span {
  display: flex;
  gap: 4px;
  position: absolute;
  left: 11px;
  border-right: 1px solid #d9d9d9;
  padding-right: 10px;
}

.profile-contact .mobileInputWrapper input {
  border-radius: 6px;
  border: 1px solid #72777a;
  background: var(--white);
  height: 40px;
  padding: 0px 12px;
  font-size: 12px;
  line-height: 14px;
}

.profile-contact input:focus-visible {
  border-color: var(--dark);
  outline: none;
}

.sendOTP-btn {
  width: 100%;
  max-width: 260px;
  border-radius: 5px !important;
  text-transform: uppercase !important;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 30px;
  border-radius: 6px;
  height: 30px;
}

@media screen and (max-width: 481px) {
  .ordersProfileWrapper {
    margin-left: 0;
  }

  .profileName {
    text-transform: capitalize;
    font-size: 11px;
  }

  .popMainContent {
    margin-bottom: 10px !important;
  }

  .popMainContent b {
    max-width: 124px;
  }

  .mobileChangePopContent {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .profile-details {
    flex-direction: column;
    gap: 0px;
  }
}


.error-msg {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: #c03;
  margin-top: 8px;
  margin-bottom: unset;
}


@media screen and (max-width: 600px) {
  .change-btn {
    padding: 0 !important;
    margin-right: -1px !important;
  }

  .gender-down-arrow img {
    margin-right: 2px !important;
  }
}

@media screen and (max-width: 480px) {
  .sendMobOTP-btn {
    margin: 12px auto 0px auto !important;
  }

  .gender-btn {
    font-size: 14px;
  }

  .otp_verify .otpInput {
    width: 40px !important;
    height: 40px !important;
    font-size: 16px;
  }
}

@media screen and (max-width: 426px) {

  .gender-btn,
  .description-heading {
    font-size: 14px;
  }

  .ProfileLine {
    margin: 10px 0;
  }
}

@media screen and (max-width: 320px) {
  .details input.otpInput {
    width: 37px !important;
  }

  .login__form {
    padding: 20px;
  }

  .btns-sec {
    margin-top: 20px;
  }

  .mobileChangePopContent {
    width: 100%;
  }

  .otpInput {
    width: 33px !important;
    height: 40px !important;
  }

  .otp_verify .otpInput {
    width: 28px !important;
    height: 40px !important;
  }

  .otp_verify .text-validation {
    margin: 10px 0 22px 0 !important;
  }
}
@media screen and (max-width: 480px) {
  .profile-details-dob {
    margin-bottom: 15px;
  }

  .profile-nudge {
    margin-top: 20px;
  }

  .details {
    min-height: 59px;
  }

  .calender-icon {
    top: 34px;
  }

  .gender-err {
    margin: 3px 0 -21px 0 !important;
  }

  .mob-input-contain {
    margin-bottom: 20px;
  }

  .form-err {
    line-height: 12px;
  }

  .details-dob {
    margin-top: -33px;
  }

  .pro-err {
    margin: 5px 0 10px 0 !important;
  }
}

.gender-input-contain {
  display: flex;
  margin-bottom: 0 !important;
}

.gender-down-arrow {
  margin-left: auto !important;
  margin-right: -3px;
}

.gender-down-arrow img {
  width: 16px;
  height: 16px;
}

.profileCouponsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ulTerm {
  margin-top: 7px !important;
}

@media screen and (min-width: 600px) {
  .detail-filed.address span {
    margin-top: 1px;
  }

  .ordersProfileWrapper h3 {
    line-height: 10px;
  }

  .mywishlist h3 {
    line-height: 22px;
  }

  .manageNotif h3 {
    line-height: 13.4px;
  }

  .loyaltypoint .sideback img {
    margin-top: -13px !important;
    margin-bottom: -11px !important;
  }

  .ordersProfileWrapper .mypro-wrap {
    padding-top: 20px !important;
  }

  .side-back {
    max-height: 11px;
  }

  .profile-wrap {
    max-height: 20px;
  }

  .datepicker-input {
    margin-left: 242px !important;
  }
}

.ordersProfileWrapper {
  overflow: visible !important;
}

@media screen and (min-width: 481px) and (max-width: 990px) {
  .mob-input-contain,
  .customDropdown {
    margin-bottom: 18px !important;
  }
}

/* NEW CSS */

.proDetWrapper {
  box-shadow: 0px 0px 8px 0px #00000014;
  padding: 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.proDetWrapper h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.proInputWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.proLabels {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.proLabels span {
  font-weight: 300;
  line-height: 22px;
  color: #787878;
  margin-bottom: 4px;
}

.proLabels .inputIcon {
  position: absolute;
  left: 12px;
  top: 34px;
}

.proLabels input {
  line-height: 21px;
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  height: 40px;
  border-radius: 6px;
  padding: 0 36px;
  color: var(--dark);
}

.proLabels input:focus-visible {
  outline: none;
  background-color: var(--white);
  border-color: var(--dark);
}

.proLabels .editIcon {
  position: absolute;
  right: 12px;
  top: 34px;
  cursor: pointer;
}

.proLabels .proErrors {
  font-size: 10px;
  line-height: 23px;
  color: #cc0033;
  margin-bottom: unset;
}

.proNudge {
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 0;
  margin-top: 20px;
}

.proNudge b {
  font-weight: 400;
  color: #23b93c;
  text-decoration: underline;
}

.proDetWrapper .proSaveBtn {
  width: 100%;
  max-width: 300px;
  height: 30px;
  margin: 30px auto 0 auto;
  border-radius: 6px;
  font-size: 10px;
  line-height: 18px;
}

.proLabels .dropdownFocus {
  background-color: var(--white);
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
  border-color: var(--white) !important;
}

.customDropdown {
  z-index: 3;
  position: relative;
}

.customDropdown ul {
  width: 100%;
  position: absolute;
  top: 62px;
  border-radius: 0px 0px 6px 6px;
  background: var(--white);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  list-style: none;
  padding-left: unset;
}

.customDropdown ul li {
  font-size: 12px;
  padding: 8px 10px;
}

.customDropdown ul li:hover {
  background-color: #f5f5f5;
}

.proLabels input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.proLabels input[type='date']::-moz-calendar-picker-indicator {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .proDetWrapper {
    padding:16px;
  }

  .proInputWrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
