.error_img {
  margin-top: 2.265rem;
  width: 12vw;
  object-fit: contain;
}

.errorbutton {
  gap: 20px;
}

.trybutton {
  line-height: 3;
  width: 17em;
  border-radius: 6px;
  margin-top: 20px;
  font-family: var(--font-family);
  margin-bottom: 50px;
}

@media screen and (max-width: 780px) {
  .error_img {
    width: 32vw;
  }
  .errorbutton {
    flex-direction: column;
    gap: 0px !important;
  }
}

@media screen and (max-width: 480px) {
  .error_img {
    width: 40vw;
  }

  .trybutton {
    font-size: 12px;
  }
}
